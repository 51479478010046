import React from "react";
import TransOffline from "@/assets/images/example-trans-offline.png"
import TransOnline from "@/assets/images/example-trans-online.png"
const Guidelines: React.FC = () => {
    return (
        <div className="mt-10">
            <h3 className="text-xl md:text-3xl mb-4 text-center text-white font-bold uppercase">
                Cách thức tham gia
            </h3>
            <ul className="list-disc pl-5 space-y-2 text-white content">
                <li>Bước 1: Quét mã QR code trên Combo</li>
                <li>Bước 2: Đăng ký thông tin</li>
                <li>Bước 3: Nhận ngay ưu đãi
                    <ul>
                        <ul className="list-disc pl-8 space-y-2 text-white content">
                            <li>Số điện thoại thành viên đã mua vé.</li>
                            <li>Chọn rạp đã mua vé.</li>
                            <li>Mã quay số: mã Trans No. (trên cuống vé) hoặc Mã Vé (nếu mua Online).
                            </li>
                            <li>[Hình ảnh: nơi tìm Mã Trans No. & Mã Vé]
                            </li>
                        </ul>
                    </ul>
                </li>
            </ul>
            <div className="flex gap-x-5 w-full mt-5 justify-center p-5">
                <div className="flex-1">
                    <img src={TransOffline} alt="Trans Offline" className="w-full h-full" />
                </div>
                <div className="flex-1">
                    <img src={TransOnline} alt="Trans Online" className="w-full h-full" />
                </div>
            </div>
        </div>
    );
};




export default Guidelines;
