import React from "react";

const Attention: React.FC = () => {
    return (
        <div className="mt-10">
            <h3 className="text-xl md:text-3xl mb-4 text-center text-white font-bold uppercase">
                Lưu ý
            </h3>
            <ul className="list-disc pl-5 space-y-2 text-white content">
                <li>Khách hàng phải giữ lại cuống vé Cô Dâu Hào Môn để đối soát khi nhận giải thưởng.</li>
                <li>Một mã quay số hợp lệ là mã Trans No/mã vé phim Cô Dâu Hào Môn tương ứng
                    với số điện thoại thành viên đã mua vé.</li>
                <li>Người thắng cuộc hợp lệ là người trình được cuống vé với mã Trans No/Mã vé phim
                    trùng khớp với mã được chọn sau khi quay số ngẫu nhiên.</li>
                <li>Galaxy Cinema có quyền hủy kết quả nếu phát hiện mã quay số trúng thưởng không hợp lệ
                    và tiến hành quay số lại để tìm người thắng cuộc hợp lệ.</li>
            </ul>
        </div>
    );
}
export default Attention;