"use client"

import {
    Toast,
    ToastClose,
    ToastDescription,
    ToastProvider,
    ToastTitle,
    ToastViewport,
} from "@/components/ui/toast"
import { useToast } from "@/hooks/use-toast"
import { CheckCircle, CircleX } from "lucide-react"

export function Toaster() {
    const { toasts } = useToast()
    return (
        <ToastProvider>
            {toasts.map(function ({ id, title, description, action, variant, ...props }) {
                return (
                    <Toast key={id} {...props}>
                        <div className="grid gap-1 relative">
                            {title && <ToastTitle>{title}</ToastTitle>}
                            {description && (
                                <ToastDescription> {description} </ToastDescription>
                            )}
                            {variant === 'default' && (
                                <span className="absolute top-1/2 -right-0 -translate-y-1/2 text-green-600">
                                    <CheckCircle size={20} />
                                </span>
                            )}
                            {variant === 'destructive' && (
                                <span className="absolute top-1/2 -right-0 -translate-y-1/2 text-red-600">
                                    <CircleX size={20} />
                                </span>
                            )}
                        </div>
                        {action}
                        <ToastClose />
                    </Toast>
                )
            })}
            <ToastViewport />
        </ToastProvider>
    )
}
