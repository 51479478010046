import React from "react";
import Logo from "@/assets/images/logo.png";
import TagLine from "@/assets/images/tagline.png";
import Gift from "@/assets/images/gift.png";
import Light from "@/assets/images/light.png";
import Attention from "./components/Attention";
import Rules from "./components/Rules";
import Guidelines from "./components/Guidelines";
import FormInput from "./components/FormInput";
import BgPage from '@/assets/images/background.png'

const LandingPage = () => {
  return (
    <div className="relative min-h-screen overflow-x-hidden">
      <img src={BgPage} alt="Background Page" className="absolute top-0 left-0 w-screen h-full object-cover -z-[1]" />
      <div className="max-w-4xl min-h-screen mx-auto">
        <header className="text-center pt-5  px-5 mx-auto">
          <img src={Logo} alt="Logo Galaxy" className="inline-block" />
          <img src={TagLine} alt="Logo Galaxy" className="inline-block" />
          <div className="relative w-full h-[180px] md:h-[380px]">
            <img
              src={Gift}
              alt="Logo Galaxy"
              className="absolute -top-5 left-0 z-10"
            />
            <img
              src={Light}
              alt="Icon Light"
              className="absolute -top-10 left-0  z-0 w-[100%] h-full scale-[2.5] md:scale-[3]"
            />
          </div>
        </header>
        <section className="relative mx-auto max-w-[325px] xs:max-w-[375px]  md:max-w-3xl rounded-3xl min-h-[1900px] -mt-[70px] xs:-mt-[55px]">
          <div className="absolute z-50 p-4 md:p-8 md:-top-16 xl:-top-10 container-form w-full shadow-lg backdrop-blur-[10px]">
            <FormInput />
            {/* Guidelines Component */}
            <Guidelines />
            {/* Rule Component  */}
            <Rules />
            {/* Attention component */}
            <Attention />
          </div>
        </section>
      </div>
    </div>
  );
};

export default LandingPage;
