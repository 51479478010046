import React, { useEffect, useState } from "react";
import cinemasData from "@/data/cinema.json";
import { useToast } from "@/hooks/use-toast";
import { APP_CONST } from "@/constants/const";
import axios from "axios";


const FormInput: React.FC = () => {
    const [cinemas, setCinemas] = React.useState<any[]>([]);
    const [selectedCinema, setSelectedCinema] = React.useState<string | number>("");
    const [phone, setPhone] = React.useState<string>("");
    const [transNo, setTransNo] = React.useState<string>("");
    const { toast } = useToast()
    const [isValid, setIsValid] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);

    const phoneRegex = /^(0|84)(2(0[3-9]|1[0-6|8|9]|2[0-2|5-9]|3[2-9]|4[0-9]|5[1|2|4-9]|6[0-3|9]|7[0-7]|8[0-9]|9[0-4|6|7|9])|3[2-9]|5[5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])([0-9]{7})$/;


    useEffect(() => {
        setCinemas(cinemasData.cinemas)
    }, [])

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCinema(event.target.value);
    }

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value) {
            setPhone(value);
            setIsValid(phoneRegex.test(value));
            return
        }
        setPhone('');
        setIsValid(true);
    }

    const handleTransNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value) {
            setTransNo(value);
            setIsEmpty(false);
            return
        }
        setTransNo('');
        setIsEmpty(true);


    }
    const handleSubmit = () => {
        if (!isValid) return
        if (!transNo) {
            setIsEmpty(true);
            return
        }
        axios.post(`${APP_CONST.END_POINT}/api/promotion/create-info`, {
            phone: phone,
            transactionNo: transNo,
            cinemaId: selectedCinema,
        }, {
            headers: {
                'clientid': `${APP_CONST.CLIENT_ID}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                toast({
                    variant: 'default',
                    title: "Đăng ký thành công",
                    description: "Chúc mừng bạn đã đăng ký thành công!",
                })
            }
        }
        ).catch((error) => {
            const { response } = error
            toast({
                variant: 'destructive',
                title: "Đăng ký không thành công",
                description: `${response.data.data.message}`,
            })
        })
    }

    return (
        <>
            <h3 className="text-xl md:text-3xl mb-4 text-center text-white font-bold">
                ĐĂNG KÝ THAM GIA
            </h3>
            <form className="space-y-4">
                <input
                    type="text"
                    placeholder="Vui lòng nhập số điện thoại thành viên"
                    className="w-full p-3 rounded text-sm color-black uppercase"
                    value={phone}
                    onChange={handlePhoneChange}
                />
                {!isValid ? (
                    <p className="mt-2 text-sm text-yellow-400">
                        Vui lòng nhập số điện thoại hợp lệ!
                    </p>
                ) : null}
                <input
                    type="text"
                    placeholder="Vui lòng nhập Trans No or mã vé"
                    className="w-full p-3 rounded text-sm color-black uppercase"
                    value={transNo}
                    onChange={handleTransNoChange}
                />
                {isEmpty ? <p className="mt-2 text-sm text-yellow-400">
                    Vui lòng nhập mã giao dịch!
                </p> : null}
                <select
                    id="product-select"
                    value={selectedCinema}
                    onChange={handleSelectChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 uppercase"
                >
                    <option value="" disabled>Rạp đã giao dịch mua vé</option>
                    {cinemas.map((cinema) => (
                        <option key={cinema.code} value={cinema.code}>
                            {cinema.name}
                        </option>
                    ))}
                </select>
                <div className="text-center">
                    <p className="text-sm text-white">
                        (*) Bằng cách chọn Đăng Ký. tôi xác nhận các thông tin tôi
                        nhập đều chính xác
                    </p>
                </div>
                <div className="text-center">
                    <button className="w-[150px] bg-[#DDF000] text-[#510000] font-bold h-[40px] rounded-[40px] text-sm transition duration-300" type="button" onClick={handleSubmit}>
                        ĐĂNG KÝ
                    </button>
                </div>
            </form>
        </>

    )
}

export default FormInput;