import React from "react"

const Rules: React.FC = () => {
    return (
        <div className="mt-10">
            <h3 className="text-xl md:text-3xl mb-4 text-center text-white font-bold uppercase">
                Thể lệ
            </h3>
            <ul className="list-disc pl-5 space-y-2 text-white content">
                <li>Áp dụng cho khách hàng mua vé phim tại hệ thống rạp Galaxy Cinema</li>
                <li>Áp dụng giờ vàng mua vé trong khung giờ 12h - 17h các ngày trong tuần</li>
                <li>Không áp dụng đồng thời với các CTKM khác</li>
                <li>Khách hàng vui lòng đọc kỹ thể lệ trước khi tham gia chương trình</li>
            </ul>
        </div>

    )
}
export default Rules